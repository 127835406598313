import {Link} from "gatsby"
import React from "react"
import './style.sass'
import FooterMenu from '../footer-menu/footer-menu';
import Social from '../social/social';
import FooterInfo from '../footer-info/footer-info';
import Copy from '../copy/copy';
import FooterLinks from '../footer-links/footer-links';
import Logo from '../../../static/images/svg/logo-new.svg';


const Footer = ({page}) => (
    <footer className="footer">
        <div className="full-wrapper">
            <div className="footer__wrapper">
                <div className="footer__container footer__container--menu">
                    <div className="footer__logo">
                        <Link
                            to="/"
                        >
                            <img src={Logo} alt="Технопарк «Сколково»"/>
                        </Link>
                    </div>
                    <div className="footer__menu">
                        <FooterMenu page={page} />
                    </div>
                </div>
                <div className="footer__container footer__container--other">
                    <div className="footer__info">
                        <FooterInfo/>
                    </div>
                    <div className="footer__social">
                        <Social/>
                    </div>
                </div>
                <div className="footer__container footer__container--top">
                    <div className="footer__copy">
                        <Copy/>
                    </div>
                    <div className="footer__links">
                        <FooterLinks/>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);


export default Footer
