import React from "react";
import './style.sass';
import tl from '../../../static/images/social/telegram.svg';
import yout from '../../../static/images/social/youtube.svg';

const Social = () => (
    <div className="social">
        <div className="social__item">
            <a href="https://t.me/technoparksk_news" target="_blank" rel="noopener noreferrer" className="social__item-link">
                <img src={tl} alt="tl"/>
            </a>
        </div>
        <div className="social__item">
            <a href="https://www.youtube.com/channel/UCsKU3ZIxVqhYu0PSRZumNTA" target="_blank" rel="noopener noreferrer" className="social__item-link">
                <img src={yout} alt="youtube"/>
            </a>
        </div>
    </div>
);


export default Social
