import React from "react"
import './style.sass'
import scrollTo from 'gatsby-plugin-smoothscroll'

const FooterMenu = ({page}) => (
    <div className="footer-menu">
            <div className="footer-menu__item">
                    {page === 'fast-page' ?
                        <a className="footer-menu__item-link" href='/#about' >О&nbsp;проекте</a>
                        :
                        <span className="footer-menu__item-link" onClick={() => scrollTo('#about')}>О&nbsp;проекте</span>
                    }
            </div>
            <div className="footer-menu__item">
                    {page === 'fast-page' ?
                        <a className="footer-menu__item-link" href='/#companies'>Кто уже с&nbsp;нами</a>
                        :
                        <span className="footer-menu__item-link" onClick={() => scrollTo('#companies')}>Кто уже с&nbsp;нами</span>
                    }
            </div>
            <div className="footer-menu__item">
                    {page === 'fast-page' ?
                        <a className="footer-menu__item-link" href='/#transport'>Как доехать</a>
                        :
                        <span className="footer-menu__item-link" onClick={() => scrollTo('#transport')}>Как доехать</span>
                    }
            </div>
            <div className="footer-menu__item">
                    {page === 'fast-page' ?
                        <a className="footer-menu__item-link" href='/#contacts'>Контакты</a>
                        :
                        <span className="footer-menu__item-link" onClick={() => scrollTo('#contacts')}>Контакты</span>
                    }
            </div>
            <div className="footer-menu__item">
                    <a href="https://sk.ru/technopark/" target='_blank' rel="noopener noreferrer" className="footer-menu__item-link">О&nbsp;Технопарке</a>
            </div>
    </div>
)

export default FooterMenu;
