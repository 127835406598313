import React from "react"
import './style.sass'

const FooterInfo = () => (
    <div className="footer-info">
       <div className="footer-info__item">
           <a href="https://sk.ru/user_agreement/" target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a>
           <a href="https://docs.sk.ru/category/passport/personal" target="_blank" rel="noopener noreferrer">Политика обработки персональных данных</a>
           <a href="mailto:sitehelp@sk.ru" >Техническая поддержка</a>
       </div>
    </div>
)


export default FooterInfo;