/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site, markdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
          frontmatter {
            seo {
              description
              title
              titleInner
              descriptionInner
              url
              image {
                publicURL
              }
              siteName
            }
          }
        }
      }
    `
  )

  const metaDescription = markdownRemark.frontmatter.seo.description || site.siteMetadata.description;

  return (

  <Helmet defaultTitle={markdownRemark.frontmatter.seo.title}>
    <html lang={lang} />
    <meta name="description" content={markdownRemark.frontmatter.seo.descriptionInner}/>
    <meta property="og:title" content={markdownRemark.frontmatter.seo.titleInner}/>
    <meta property="og:description" content={markdownRemark.frontmatter.seo.descriptionInner}/>
    <meta property="og:url" content={markdownRemark.frontmatter.seo.url} />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content={markdownRemark.frontmatter.seo.siteName} />

    <meta property="og:locale" content="ru_RU" />
    <meta property="og:image" content="https://superallin.sk.ru/og-allin.jpg" />
    <meta property="og:image:width" content="968" />
    <meta property="og:image:height" content="504" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@TechnoparkSk" />
    <meta name="twitter:title" content={markdownRemark.frontmatter.seo.titleInner} />
    <meta name="twitter:description" content={markdownRemark.frontmatter.seo.descriptionInner} />
    <meta name="twitter:image" content="https://superallin.sk.ru/og-allin.jpg" />
    <meta name="twitter:url" content={markdownRemark.frontmatter.seo.url} />
    <meta name="twitter:domain" content="superallin.sk.ru" />
    <meta name="yandex-verification" content="d90018ad0db8a45c" />

  </Helmet>

  )
}

SEO.defaultProps = {
  lang: `ru`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
