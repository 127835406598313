import React from "react"
import './style.sass'

const Copy = () => (
    <div className="copy">
        <div className="copy__item">
            Создание сайта <a href="https://webpractik.ru/" target="_blank" rel="noopener noreferrer">Вебпрактик</a>
        </div>
    </div>
)


export default Copy;