import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import scrollTo from 'gatsby-plugin-smoothscroll';

class Menu extends Component {
    handleClick(selector) {
        scrollTo(selector);
        this.props.closeMenu();
    }

    render() {
        return (
            <div className="menu">
                <div className="menu__item">
                    {
                        this.props.page === 'fast-page' ?
                            <a href='/#about' className="menu__item-link" >О&nbsp;проекте</a>
                            :
                            <span className="menu__item-link" onClick={this.handleClick.bind(this, '#about')}>О&nbsp;проекте</span>
                    }
                </div>
                <div className="menu__item">
                    {
                        this.props.page === 'fast-page' ?
                            <a className="menu__item-link" href='/#companies' >Кто уже с&nbsp;нами</a>
                            :
                            <span className="menu__item-link" onClick={this.handleClick.bind(this, '#companies')}>Кто уже с&nbsp;нами</span>
                    }
                </div>
                <div className="menu__item">
                    {
                        this.props.page === 'fast-page' ?
                            <a className="menu__item-link" href='/#transport' >Как доехать</a>
                            :
                            <span className="menu__item-link" onClick={this.handleClick.bind(this, '#transport')}>Как доехать</span>
                    }
                </div>
                <div className="menu__item">
                    {
                        this.props.page === 'fast-page' ?
                            <a className="menu__item-link" href='/#contacts' >Контакты</a>
                            :
                            <span className="menu__item-link" onClick={this.handleClick.bind(this, '#contacts')}>Контакты</span>
                    }
                </div>
                <div className="menu__item">
                    <a href="https://sk.ru/technopark/" target='_blank' rel="noopener noreferrer" className="menu__item-link">О Технопарке</a>
                </div>
                {/*<div className="menu__item">*/}
                {/*  <button className="menu__btn" onClick={this.handleClick.bind(this, '#tarif')}>Оплатить</button>*/}
                {/*</div>*/}
            </div>
        );
    }
}

Menu.propTypes = {};

export default Menu;
