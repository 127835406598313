import React from "react"
import './style.sass'

const FooterLinks = () => (
    <div className="footer-links">
        <div className="footer-links__item">
            Все материалы сайта доступны по&nbsp;лицензии: Creative Commons Attribution&nbsp;4.0 International
        </div>
    </div>
)


export default FooterLinks;